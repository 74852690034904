<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import { authComputed } from "../../../state/helpers";
import Axios from "@/config/axios";
import store from "../../../state/store";

export default {
  page: {
    title: "Setting",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Setting",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Setting",
          active: true,
        },
      ],
      value: ["javascript"],
      date: null,
      fields: {
        username: "",
        email: "",
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      if (this.fields.new_password != this.fields.confirm_password) {
        alert("Les mots de passes ne correspondent pas !");
        return;
      }

      if (this.fields.new_password.length < 6) {
        alert("Le mot de passe doit contenir au moins 6 caractères !");
        return;
      }

      store.dispatch("setGlobalLoading", true);

      Axios.post("/admin/auth/update", this.fields)
        .then(({ data }) => {
          console.log(data);
          alert("Modificaton enregistrée");
          window.location.reload();
        })
        .catch((e) => {
          if (e.response?.data?.message) {
            alert(e.response.data.message);
          }
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },
  },
  computed: { ...authComputed },
  mounted() {
    this.fields.username = this.currentUser.username;
    this.fields.email = this.currentUser.email;
  },
  components: {
    Layout,
  },
};
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img
          src="@/assets/images/profile-bg.jpg"
          class="profile-wid-img"
          alt=""
        />
      </div>
    </div>

    <div class="row">
      <!--end col-->
      <div class="col-xxl-12">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul
              class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-bs-toggle="tab"
                  href="#personalDetails"
                  role="tab"
                >
                  <i class="fas fa-home"></i>
                  Informations personnelles
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  href="#changePassword"
                  role="tab"
                >
                  <i class="far fa-user"></i>
                  Modification du mot de passe
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="personalDetails" role="tabpanel">
                <form
                  action="javascript:void(0);"
                  @submit.prevent="handleSubmit"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="firstnameInput" class="form-label"
                          >Nom d'utilisateur</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="firstnameInput"
                          placeholder="Enter your username"
                          v-model="fields.username"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="emailInput" class="form-label"
                          >Adresse Email</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="emailInput"
                          placeholder="Enter your email"
                          v-model="fields.email"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary">
                          Enregistrer
                        </button>
                        <button type="button" class="btn btn-soft-success">
                          Annuler
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form
                  action="javascript:void(0);"
                  @submit.prevent="handleSubmit"
                >
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="oldpasswordInput" class="form-label"
                          >Mot de passe actuel*</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="oldpasswordInput"
                          placeholder="Enter current password"
                          v-model="fields.old_password"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label"
                          >Nouveau mot de passe*</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="newpasswordInput"
                          placeholder="Enter new password"
                          v-model="fields.new_password"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label"
                          >Confirmer le nouveau mot de passe*</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          id="confirmpasswordInput"
                          placeholder="Confirm password"
                          v-model="fields.confirm_password"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12 mt-2">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success">
                          Modifier le mot de passe
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
